@font-face {
    font-family: 'Roc_Grotesk_Bold';
    src: url('../fonts/Roc_Grotesk_Bold.eot');
    src: url('../fonts/Roc_Grotesk_Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Roc_Grotesk_Bold.ttf') format('truetype'),
         url('../fonts/Roc_Grotesk_Bold.woff') format('woff'),
         url('../fonts/Roc_Grotesk_Bold.woff2') format('woff2');
  }

@font-face {
    font-family: 'Roc_Grotesk_Wide';
    src: url('../fonts/Roc_Grotesk_Wide.eot');
    src: url('../fonts/Roc_Grotesk_Wide.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Roc_Grotesk_Wide.ttf') format('truetype'),
         url('../fonts/Roc_Grotesk_Wide.woff') format('woff'),
         url('../fonts/Roc_Grotesk_Wide.woff2') format('woff2');
  }