/* You can add global styles to this file, and also import other style files */
// html {zoom: 90%;}

.no-display {
    display: none !important;
}

.cylinder {
  /* this variable will define how much ci*/
  --r: 40px;
  /* whatever values/units you want */
  width: 250px;
  height: 400px;
  // height: 80px;
  // width: 80px;
  animation: move-left-right 9s infinite;
  left: 10%;
  top: 35%;
  background: 
    radial-gradient(50% var(--r) at 50% var(--r), #0003 99.99%, #0000 0),
    radial-gradient(50% var(--r) at 50% calc(100% - var(--r)), #fff3 99.99%, #0000 0),
    #369;
  border-radius: 100% / calc(var(--r) * 2);
}

@media screen and (max-width: 767px) {
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="password"] {
      font-size: 16px;
    }
  }